<template>
  <div>
    <h4 class="mb-2">
      Create new tournament
    </h4>
    <p>
      You can always edit all information and size of tournament later but try
      to enter accurate information when creating tournament
    </p>

    <validation-observer ref="validation">
      <b-form @submit.prevent>
        <b-row>
          <!-- Tournament data -->
          <b-col cols="9">
            <!-- LEFT form data -->
            <b-card>
              <b-row>
                <b-col
                  lg="6"
                  md="12"
                >
                  <b-form-group
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    label="Tournament name"
                    label-for="tournament-name"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Tournament name"
                      rules="required"
                      vid="tournament-name"
                    >
                      <b-form-input
                        id="tournament-name"
                        v-model="name"
                        placeholder="Tournament name"
                        maxlength="70"
                      />
                      <small class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- tournament type -->
                  <b-form-group
                    label="Tournament type"
                    label-for="tournament-type"
                  >
                    <b-form-radio-group
                      v-model="tournamentType"
                      :options="tournamentTypeOptions"
                      name="tournament-type"
                    />
                  </b-form-group>

                  <!-- discipline category -->
                  <b-form-group
                    label="Discipline category"
                    label-for="discipline-category"
                  >
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Discipline category"
                      rules="required"
                    >
                      <v-select
                        id="discipline-category"
                        v-model="disciplineCategory"
                        :options="fetchDisciplineCategories"
                        :reduce="category => category.code"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- discipline  -->
                  <b-form-group
                    label="Discipline"
                    label-for="discipline"
                  >
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Discipline"
                      rules="required"
                    >
                      <v-select
                        id="discipline"
                        v-model="discipline"
                        :options="fetchDisciplines"
                        :reduce="d => d.code"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- platforms -->
                  <b-form-group
                    label="Select platform (if cross-play is supported you can select more than one)"
                    label-for="platforms"
                  >
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Platform"
                      rules="required"
                    >
                      <v-select
                        id="platforms"
                        v-model="platforms"
                        :options="fetchPlatforms"
                        multiple
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- participants number -->
                  <b-form-group
                    label="Participant format "
                    label-for="participant-type"
                  >
                    <b-form-radio-group
                      id="participant-type"
                      v-model="participantsType"
                      :options="participantTypeOptions"
                      name="participant-type"
                    />
                  </b-form-group>

                  <b-form-group
                    label="Max participants (size)"
                    label-for="participants-number"
                  >
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Participants number"
                      rules="required"
                    >
                      <b-form-input
                        id="participants-number"
                        v-model="participantsNumber"
                        placeholder="Enter number"
                        type="number"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    v-if="participantsType === 1"
                    label="Number of players per team (enter minimum players that must be in team so the team is able to join)"
                    label-for="players-per-team"
                  >
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Players per team"
                      rules="integer"
                    >
                      <b-form-input
                        id="players-per-team"
                        v-model="playersPerTeam"
                        placeholder="Enter number"
                        type="number"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Tournament start-->
                  <label>Tournament start</label>
                  <validation-provider
                    v-slot="{ errors: componentErrors }"
                    name="Tournament start"
                    rules="required"
                  >
                    <b-row>
                      <b-col cols="6">
                        <b-form-datepicker
                          v-model="startDate"
                          :locale="getUserLocale()"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          :start-weekday="1"
                          class="mb-1"
                          name="start-date"
                          placeholder=""
                        />
                      </b-col>
                      <b-col cols="6">
                        <b-form-timepicker
                          v-model="startTime"
                          :hour12="false"
                          locale="en"
                          placeholder=""
                        />
                      </b-col>
                    </b-row>
                    <small class="text-danger">
                      {{ componentErrors[0] }}
                    </small>
                  </validation-provider>

                  <!-- Timezone -->
                  <b-form-group
                    class="mt-1 mb-2"
                    label="Timezone"
                    label-for="timezone"
                  >
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Timezone"
                      rules="required"
                    >
                      <v-select
                        id="timezone"
                        v-model="timezone"
                        :class="{ 'is-invalid': !!componentErrors.length }"
                        :options="timezonesList"
                        :reduce="timezone => timezone.code"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  lg="6"
                  md="12"
                >

                  <!-- Structure -->
                  <b-form-group>
                    <label for="reason">Type</label>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="type"
                      rules="required"
                    >
                      <v-select
                        id="type"
                        v-model="structure"
                        :options="typeOptions"
                        :reduce="type => type.code"
                        name="type"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- General Format -->
                  <b-form-group
                    v-if="structure !== TournamentFormat.RACE"
                    class="flex-fill"
                  >
                    <label for="reason">General format</label>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="format"
                      :rules="generalFormatValidationRules"
                    >
                      <v-select
                        id="type"
                        v-model="generalFormat"
                        :options="matchFormatOptions"
                        :reduce="format => format.code"
                        name="format"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- 3rd/4th decider match?   -->
                  <b-form-group
                    v-if="structure === 1 || structure === 2"
                    label="3rd/4th decider match"
                    label-for="third-place-match"
                  >
                    <b-form-checkbox
                      v-model="thirdPlaceMatch"
                      checked="true"
                      class="custom-control-primary"
                      name="third-place-match"
                      switch
                    />
                  </b-form-group>

                  <!-- Finals have format -->
                  <b-form-group
                    v-if="structure === 1 || structure === 2"
                    label="Finals have other format?"
                    label-for="grand-final-match"
                  >
                    <b-form-checkbox
                      v-model="finalsHasOtherFormat"
                      checked="true"
                      class="custom-control-primary"
                      name="grand-final-match"
                      switch
                    />
                  </b-form-group>

                  <!-- Finals have other format -->
                  <b-form-group
                    v-if="finalsHasOtherFormat"
                    class="flex-fill"
                  >
                    <label for="reason">Finals format</label>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="format"
                      rules="required"
                    >
                      <v-select
                        id="type"
                        v-model="finalsFormat"
                        :options="matchFormatOptions"
                        :reduce="format => format.code"
                        name="format"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Lower bracket finals have format -->
                  <b-form-group
                    v-if="structure === 2"
                    label="Lower bracket finals have other format?"
                    label-for="lower-bracket-finals-match"
                  >
                    <b-form-checkbox
                      v-model="lowerBracketFinalsHaveOtherFormat"
                      checked="true"
                      class="custom-control-primary"
                      name="lower-bracket-finals-match"
                      switch
                    />
                  </b-form-group>

                  <!-- Lower bracket finals have other format -->
                  <b-form-group
                    v-if="lowerBracketFinalsHaveOtherFormat"
                    class="flex-fill"
                  >
                    <label for="reason">Lower bracket finals format</label>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="format"
                      rules="required"
                    >
                      <v-select
                        id="type"
                        v-model="lowerBracketFinalsFormat"
                        :options="matchFormatOptions"
                        :reduce="format => format.code"
                        name="format"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Semifinals have format -->
                  <b-form-group
                    v-if="structure === 1 || structure === 2"
                    label="Semi-Final have other format?"
                    label-for="semi-finals-match"
                  >
                    <b-form-checkbox
                      v-model="semiFinalsHaveOtherFormat"
                      checked="true"
                      class="custom-control-primary"
                      name="semi-finals-match"
                      switch
                    />
                  </b-form-group>

                  <!-- Semifinals have other format -->
                  <b-form-group
                    v-if="semiFinalsHaveOtherFormat"
                    class="flex-fill"
                  >
                    <label for="reason">Semi-Final format?</label>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="format"
                      rules="required"
                    >
                      <v-select
                        id="type"
                        v-model="semiFinalsFormat"
                        :options="matchFormatOptions"
                        :reduce="format => format.code"
                        name="format"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <hr>

                  <!-- Enable registrations -->
                  <b-form-checkbox
                    v-model="enableRegistration"
                    class="mt-1 mb-1"
                  >
                    Enable registrations
                  </b-form-checkbox>
                  <label>
                    If checked users have to register and join tournament on our
                    platform. If you are running offline event or you want to
                    enter players/teams and score manually then please uncheck
                    this option. When you start tournament empty bracket will
                    appear and you will have to enter teams/players.
                  </label>

                  <!-- Open registration-->
                  <div v-if="enableRegistration">
                    <label>Registration open</label>
                    <b-row>
                      <b-col cols="6">
                        <b-form-datepicker
                          v-model="registrationOpenDate"
                          :locale="getUserLocale()"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          :start-weekday="1"
                          class="mb-1"
                          name="checkin-start-date"
                          placeholder=""
                        />
                      </b-col>
                      <b-col cols="6">
                        <b-form-timepicker
                          v-model="registrationOpenTime"
                          :hour12="false"
                          locale="en"
                          placeholder=""
                        />
                      </b-col>
                    </b-row>

                    <!-- Close registration-->
                    <label>Registration close</label>
                    <b-row>
                      <b-col cols="6">
                        <b-form-datepicker
                          v-model="registrationCloseDate"
                          :locale="getUserLocale()"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          :start-weekday="1"
                          class="mb-1"
                          name="checkin-start-date"
                          placeholder=""
                        />
                      </b-col>
                      <b-col cols="6">
                        <b-form-timepicker
                          v-model="registrationCloseTime"
                          :hour12="false"
                          locale="en"
                          placeholder=""
                        />
                      </b-col>
                    </b-row>
                  </div>

                  <!-- Enable Checkin -->
                  <b-form-checkbox
                    v-model="enableCheckin"
                    class="mt-1 mb-1"
                  >
                    Enable Check-in
                  </b-form-checkbox>
                  <label class="mb-2">
                    If check-in is enabled teams/players need to check-in before
                    tournament starts and only checked-in teams/players can
                    participate. You can later setup when check-in starts and
                    ends. This option can be very useful for free tournaments to
                    avoid more than usual no-show matches in tournament. Keep in
                    mind that teams/players that don’t check-in in time and
                    tournament is started they can’t join and participate.
                  </label>

                  <!-- Checkin start-->
                  <div v-if="enableCheckin">
                    <label>Checkin start</label>
                    <b-row>
                      <b-col cols="6">
                        <b-form-datepicker
                          v-model="checkinStartDate"
                          :locale="getUserLocale()"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          :start-weekday="1"
                          class="mb-1"
                          name="checkin-start-date"
                          placeholder=""
                        />
                      </b-col>
                      <b-col cols="6">
                        <b-form-timepicker
                          v-model="checkinStartTime"
                          :hour12="false"
                          locale="en"
                          placeholder=""
                        />
                      </b-col>
                    </b-row>

                    <!-- Checking close-->
                    <label>Checkin close</label>
                    <b-row>
                      <b-col cols="6">
                        <b-form-datepicker
                          v-model="checkinCloseDate"
                          :locale="getUserLocale()"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          :start-weekday="1"
                          class="mb-1"
                          name="checkin-close-date"
                          placeholder=""
                        />
                      </b-col>
                      <b-col cols="6">
                        <b-form-timepicker
                          v-model="checkinCloseTime"
                          :hour12="false"
                          locale="en"
                          placeholder=""
                        />
                      </b-col>
                    </b-row>
                  </div>

                  <!-- Checkin end on start -->
                  <b-form-group>
                    <b-form-checkbox
                      v-model="checkinEndOnStart"
                      class="mt-1"
                    >
                      End Checkin when tournament is started
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Participant & other info -->
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Below you can add all info for participants, mention sponsors etc."
                    label-for="participants-info"
                  >
                    <b-form-textarea
                      v-model="info"
                      name="participants-info"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Tournament rules -->
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Here please add only tournament rules"
                    label-for="tournament-rules"
                  >
                    <b-form-textarea
                      v-model="rules"
                      name="tournament-rules"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <!-- RIGHT tournament data -->
          <b-col cols="3">
            <!-- Prize data -->
            <b-card
              class=""
              title="Tournament type and prizes"
            >
              <b-row>
                <b-col cols="12">
                  <!-- Entry Fee? -->
                  <b-form-group>
                    <b-form-checkbox
                      v-model="hasEntryFee"
                      class="mt-1"
                    >
                      Enable participation fee
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- Fee per team/player -->
                  <b-form-group
                    v-if="hasEntryFee"
                    label="Entry fee for player/team"
                    label-for="fee-amount"
                  >
                    <b-form-input
                      id="fee-amount"
                      v-model="entryFee"
                      placeholder="Fee amount"
                      type="number"
                    />
                  </b-form-group>

                  <!-- Fee per team/player -->
                  <b-form-group
                    v-if="hasEntryFee"
                    label="Your fee in % (earning)"
                    label-for="fee-amount-percent"
                  >
                    <b-form-input
                      id="fee-amount-percent"
                      v-model="organizerFee"
                      placeholder="Fee amount"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- prize distribution -->
              <b-row v-if="entryFee" />

              <!-- additional prizes -->
              <b-row>
                <b-col>
                  <b-form-group
                    label="Add your prizes"
                    label-for="prizes"
                  >
                    <label class="mt-1 mb-1">
                      Add your prizes If you have prizes that will be delivered
                      by you please enter them below (each prize in new line).
                      Keep in mind that its crucial for you to deliver promised
                      prizes after event. If you would like to give money prize
                      to participants please get in touch with us.
                    </label>
                    <b-form-textarea
                      v-model="prizes"
                      name="prizes"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>

            <!-- Tournament staff -->
            <b-card
              class=""
              title="Tournament staff"
            >
              <validation-provider
                v-slot="{ errors: componentErrors }"
                name="Staff"
                rules="required"
              >
                <b-form-checkbox
                  v-for="member in organizer?.members"
                  :key="member.user?.id"
                  v-model="staff"
                  :button-variant="
                    staff && staff.indexOf(member.user?.id) !== -1
                      ? 'primary'
                      : 'outline-primary'
                  "
                  :value="member.user?.id"
                  button
                  class="mr-1"
                  size="sm"
                >
                  {{ member.user?.name }}
                </b-form-checkbox>

                <div class="mt-1">
                  <small class="text-danger">
                    {{ componentErrors[0] }}
                  </small>
                </div>
              </validation-provider>
            </b-card>

            <dismissible-message-box
              :auto-dismiss="5000"
              :show="showErrorNotifications"
              type="danger"
            >
              <feather-icon
                class="mr-50"
                icon="InfoIcon"
              />
              {{ errors[0] }}
            </dismissible-message-box>

            <dismissible-message-box
              v-if="showSuccessNotifications"
              :auto-dismiss="5000"
              :show="showSuccessNotifications"
              type="success"
            >
              Tournament successfully created
            </dismissible-message-box>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click.prevent="createTournament"
            >
              Create new tournament
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { Timezones } from '@/constants/Timezones'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTextarea,
  BFormTimepicker,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import { required } from '@validations'
import TournamentType from '@/constants/TournamentType'
import Platforms from '@/constants/Platforms'
import Disciplines from '@/constants/Disciplines'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import DisciplineCategory from '@/constants/DisciplineCategory'
import { getUserLocale } from '@/views/common'
import TournamentFormat from '@/constants/TournamentFormat'

export default {
  components: {
    DismissibleMessageBox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BButton,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormTimepicker,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      matchFormatOptions: [
        {
          code: 1,
          label: 'Best of 1',
        },
        {
          code: 2,
          label: 'Best of 2',
        },
        {
          code: 3,
          label: 'Best of 3',
        },
        {
          code: 4,
          label: 'Best of 4',
        },
        {
          code: 5,
          label: 'Best of 5',
        },
      ],
      typeOptions: [
        {
          code: 1,
          label: 'Single Elimination',
        },
        {
          code: 2,
          label: 'Double Elimination',
        },
        // {
        //   code: 3,
        //   label: 'Round Robin'
        // }
        {
          code: 4,
          label: 'Race',
        },
      ],
      tournamentTypeOptions: [
        {
          text: 'Public',
          value: 1,
        },
        {
          text: 'Private',
          value: 2,
        },
      ],
      participantTypeOptions: [
        {
          text: 'Teams',
          value: 1,
        },
        {
          text: 'Players',
          value: 2,
        },
      ],
      TournamentType,
      TournamentParticipantType,
      required,
      isLoading: true,
      isActive: false,
      name: null,
      disciplineCategory: null,
      discipline: null,
      participantsType: 1, // default team
      playersPerTeam: null,
      participantsNumber: null,
      enableCheckin: false,
      enableRegistration: false,
      structure: null,
      generalFormat: null,
      thirdPlaceMatch: false,
      finalsHasOtherFormat: false,
      finalsFormat: null,
      semiFinalsFormat: null,
      semiFinalsHaveOtherFormat: false,
      lowerBracketFinalsHaveOtherFormat: false,
      lowerBracketFinalsFormat: null,
      registrationOpenDate: null,
      registrationOpenTime: null,
      registrationCloseDate: null,
      registrationCloseTime: null,
      startDate: null,
      startTime: null,
      checkinStartDate: null,
      checkinStartTime: null,
      checkinCloseDate: null,
      checkinCloseTime: null,
      timezone: null,
      timezonesList: Timezones.map(timezone => ({ label: timezone.value, code: timezone.id })),
      checkinEndOnStart: false,
      hasEntryFee: false,
      entryFee: null,
      organizerFee: null,
      prizes: null,
      tournamentStaff: [],
      platforms: null,
      staff: [],
      tournamentType: TournamentType.OPEN,
      info: null,
      rules: null,
      showErrorNotifications: false,
      showSuccessNotifications: false,
      errors: [],
    }
  },
  computed: {
    TournamentFormat() {
      return TournamentFormat
    },
    organizer() {
      return this.$store.getters['organizer/getDashboardOrganizer']
    },
    fetchDisciplines() {
      if (!this.disciplineCategory) {
        return []
      }

      return Disciplines.filter(discipline => discipline.category_id === this.disciplineCategory)
        .map(discipline => ({ label: discipline.title, code: discipline.id }))
    },
    fetchDisciplineCategories() {
      return DisciplineCategory.map(
        item => ({ label: item.title, code: item.id }),
      )
    },
    fetchPlatforms() {
      return Platforms.map(item => ({ label: item.title, code: Number(item.id) }))
    },
    generalFormatValidationRules() {
      return this.structure === TournamentFormat.RACE ? '' : 'required'
    },
  },

  methods: {
    getUserLocale,
    async createTournament() {
      this.showErrorNotifications = false
      this.showSuccessNotifications = false
      this.errors = []

      const formValid = await this.$refs.validation.validate()

      if (!formValid) {
        return
      }

      // eslint-disable-next-line no-unreachable
      const payload = {
        organizer_id: Number(this.$route.params.id),
        name: this.name,
        discipline_id: Number(this.discipline),
        image: null,
        participants_number: Number(this.participantsNumber),
        participant_type_id: this.participantsType,
        players_per_team: this.playersPerTeam
          ? Number(this.playersPerTeam)
          : null,
        timezone_id: this.timezone,
        platforms: this.platforms
          ? this.platforms.map(platform => platform.code)
          : [],
        info: this.info,
        rules: this.rules,
        password: null,
        start_at: `${this.startDate} ${this.startTime}`,
        enable_registration: this.enableRegistration,
        registration_open_at: this.enableRegistration
          ? `${this.registrationOpenDate} ${this.registrationOpenTime}`
          : null,
        registration_close_at: this.enableRegistration
          ? `${this.registrationCloseDate} ${this.registrationCloseTime}`
          : null,
        enable_checkin: this.enableCheckin,
        checkin_start_at: this.enableCheckin
          ? `${this.checkinStartDate} ${this.checkinStartTime}`
          : null,
        checkin_close_at: this.enableCheckin
          ? `${this.checkinCloseDate} ${this.checkinCloseTime}`
          : null,
        checkin_end_on_tournament_start: this.checkinEndOnStart,
        has_participation_fee: this.hasEntryFee,
        entry_fee: this.entryFee ? parseFloat(this.entryFee) : null,
        organizer_fee: this.organizerFee
          ? parseFloat(this.organizerFee)
          : null,
        staff: this.staff ? this.staff : null,
        prizes: this.prizes,
        tournament_type_id: this.tournamentType,
        settings: {
          tournament_format: this.structure,
          general_match_format: this.generalFormat,
          third_place_match: this.thirdPlaceMatch,
          finals_match_format: this.finalsFormat,
          semi_finals_match_format: this.semiFinalsFormat,
          lower_bracket_finals_format: this.lowerBracketFinalsFormat,
        },
      }

      const { data, errors } = await this.$api.tournament.createTournament(
        payload,
      )

      if (errors && errors.length) {
        this.errors.push(
          'There was an error creating tournament. Please try again or contact our support team.',
        )

        this.showErrorNotifications = true

        return
      }

      await this.$router.push({
        name: 'dashboard-tournament-overview',
        params: {
          id: Number(this.$route.params.id),
          tournamentId: data.id,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
