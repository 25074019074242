const DisciplineCategory = [
  {
    id: 1, title: 'esport',
  },
  {
    id: 2, title: 'sport',
  },
]

export default DisciplineCategory
