const Platforms = [
  { id: 1, title: 'PC', slug: 'PC' },
  { id: 2, title: 'Playstation 4', slug: 'PS4' },
  { id: 3, title: 'Playstation 5', slug: 'PS5' },
  { id: 4, title: 'Xbox One', slug: 'XBOX_ONE' },
  { id: 5, title: 'Switch', slug: 'SWITCH' },
  { id: 6, title: 'Mobile', slug: 'MOB' },
]

export default Platforms
