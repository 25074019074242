var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" Create new tournament ")]), _c('p', [_vm._v(" You can always edit all information and size of tournament later but try to enter accurate information when creating tournament ")]), _c('validation-observer', {
    ref: "validation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('b-form-group', {
    class: _vm.errors.length > 0 ? 'is-invalid' : null,
    attrs: {
      "label": "Tournament name",
      "label-for": "tournament-name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tournament name",
      "rules": "required",
      "vid": "tournament-name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "tournament-name",
            "placeholder": "Tournament name",
            "maxlength": "70"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tournament type",
      "label-for": "tournament-type"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "options": _vm.tournamentTypeOptions,
      "name": "tournament-type"
    },
    model: {
      value: _vm.tournamentType,
      callback: function callback($$v) {
        _vm.tournamentType = $$v;
      },
      expression: "tournamentType"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Discipline category",
      "label-for": "discipline-category"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Discipline category",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var componentErrors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "discipline-category",
            "options": _vm.fetchDisciplineCategories,
            "reduce": function reduce(category) {
              return category.code;
            }
          },
          model: {
            value: _vm.disciplineCategory,
            callback: function callback($$v) {
              _vm.disciplineCategory = $$v;
            },
            expression: "disciplineCategory"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Discipline",
      "label-for": "discipline"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Discipline",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var componentErrors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "discipline",
            "options": _vm.fetchDisciplines,
            "reduce": function reduce(d) {
              return d.code;
            }
          },
          model: {
            value: _vm.discipline,
            callback: function callback($$v) {
              _vm.discipline = $$v;
            },
            expression: "discipline"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Select platform (if cross-play is supported you can select more than one)",
      "label-for": "platforms"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Platform",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var componentErrors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "id": "platforms",
            "options": _vm.fetchPlatforms,
            "multiple": ""
          },
          model: {
            value: _vm.platforms,
            callback: function callback($$v) {
              _vm.platforms = $$v;
            },
            expression: "platforms"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Participant format ",
      "label-for": "participant-type"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "participant-type",
      "options": _vm.participantTypeOptions,
      "name": "participant-type"
    },
    model: {
      value: _vm.participantsType,
      callback: function callback($$v) {
        _vm.participantsType = $$v;
      },
      expression: "participantsType"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Max participants (size)",
      "label-for": "participants-number"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Participants number",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var componentErrors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "participants-number",
            "placeholder": "Enter number",
            "type": "number"
          },
          model: {
            value: _vm.participantsNumber,
            callback: function callback($$v) {
              _vm.participantsNumber = $$v;
            },
            expression: "participantsNumber"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _vm.participantsType === 1 ? _c('b-form-group', {
    attrs: {
      "label": "Number of players per team (enter minimum players that must be in team so the team is able to join)",
      "label-for": "players-per-team"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Players per team",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var componentErrors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "players-per-team",
            "placeholder": "Enter number",
            "type": "number"
          },
          model: {
            value: _vm.playersPerTeam,
            callback: function callback($$v) {
              _vm.playersPerTeam = $$v;
            },
            expression: "playersPerTeam"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 1182130526)
  })], 1) : _vm._e(), _c('label', [_vm._v("Tournament start")]), _c('validation-provider', {
    attrs: {
      "name": "Tournament start",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var componentErrors = _ref7.errors;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-datepicker', {
          staticClass: "mb-1",
          attrs: {
            "locale": _vm.getUserLocale(),
            "date-format-options": {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            },
            "start-weekday": 1,
            "name": "start-date",
            "placeholder": ""
          },
          model: {
            value: _vm.startDate,
            callback: function callback($$v) {
              _vm.startDate = $$v;
            },
            expression: "startDate"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-timepicker', {
          attrs: {
            "hour12": false,
            "locale": "en",
            "placeholder": ""
          },
          model: {
            value: _vm.startTime,
            callback: function callback($$v) {
              _vm.startTime = $$v;
            },
            expression: "startTime"
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  }), _c('b-form-group', {
    staticClass: "mt-1 mb-2",
    attrs: {
      "label": "Timezone",
      "label-for": "timezone"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Timezone",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var componentErrors = _ref8.errors;
        return [_c('v-select', {
          class: {
            'is-invalid': !!componentErrors.length
          },
          attrs: {
            "id": "timezone",
            "options": _vm.timezonesList,
            "reduce": function reduce(timezone) {
              return timezone.code;
            }
          },
          model: {
            value: _vm.timezone,
            callback: function callback($$v) {
              _vm.timezone = $$v;
            },
            expression: "timezone"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("Type")]), _c('validation-provider', {
    attrs: {
      "name": "type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var componentErrors = _ref9.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.typeOptions,
            "reduce": function reduce(type) {
              return type.code;
            },
            "name": "type"
          },
          model: {
            value: _vm.structure,
            callback: function callback($$v) {
              _vm.structure = $$v;
            },
            expression: "structure"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _vm.structure !== _vm.TournamentFormat.RACE ? _c('b-form-group', {
    staticClass: "flex-fill"
  }, [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("General format")]), _c('validation-provider', {
    attrs: {
      "name": "format",
      "rules": _vm.generalFormatValidationRules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var componentErrors = _ref10.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.matchFormatOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "format"
          },
          model: {
            value: _vm.generalFormat,
            callback: function callback($$v) {
              _vm.generalFormat = $$v;
            },
            expression: "generalFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 2960058931)
  })], 1) : _vm._e(), _vm.structure === 1 || _vm.structure === 2 ? _c('b-form-group', {
    attrs: {
      "label": "3rd/4th decider match",
      "label-for": "third-place-match"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": "true",
      "name": "third-place-match",
      "switch": ""
    },
    model: {
      value: _vm.thirdPlaceMatch,
      callback: function callback($$v) {
        _vm.thirdPlaceMatch = $$v;
      },
      expression: "thirdPlaceMatch"
    }
  })], 1) : _vm._e(), _vm.structure === 1 || _vm.structure === 2 ? _c('b-form-group', {
    attrs: {
      "label": "Finals have other format?",
      "label-for": "grand-final-match"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": "true",
      "name": "grand-final-match",
      "switch": ""
    },
    model: {
      value: _vm.finalsHasOtherFormat,
      callback: function callback($$v) {
        _vm.finalsHasOtherFormat = $$v;
      },
      expression: "finalsHasOtherFormat"
    }
  })], 1) : _vm._e(), _vm.finalsHasOtherFormat ? _c('b-form-group', {
    staticClass: "flex-fill"
  }, [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("Finals format")]), _c('validation-provider', {
    attrs: {
      "name": "format",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var componentErrors = _ref11.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.matchFormatOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "format"
          },
          model: {
            value: _vm.finalsFormat,
            callback: function callback($$v) {
              _vm.finalsFormat = $$v;
            },
            expression: "finalsFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 2436172954)
  })], 1) : _vm._e(), _vm.structure === 2 ? _c('b-form-group', {
    attrs: {
      "label": "Lower bracket finals have other format?",
      "label-for": "lower-bracket-finals-match"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": "true",
      "name": "lower-bracket-finals-match",
      "switch": ""
    },
    model: {
      value: _vm.lowerBracketFinalsHaveOtherFormat,
      callback: function callback($$v) {
        _vm.lowerBracketFinalsHaveOtherFormat = $$v;
      },
      expression: "lowerBracketFinalsHaveOtherFormat"
    }
  })], 1) : _vm._e(), _vm.lowerBracketFinalsHaveOtherFormat ? _c('b-form-group', {
    staticClass: "flex-fill"
  }, [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("Lower bracket finals format")]), _c('validation-provider', {
    attrs: {
      "name": "format",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var componentErrors = _ref12.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.matchFormatOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "format"
          },
          model: {
            value: _vm.lowerBracketFinalsFormat,
            callback: function callback($$v) {
              _vm.lowerBracketFinalsFormat = $$v;
            },
            expression: "lowerBracketFinalsFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 892768433)
  })], 1) : _vm._e(), _vm.structure === 1 || _vm.structure === 2 ? _c('b-form-group', {
    attrs: {
      "label": "Semi-Final have other format?",
      "label-for": "semi-finals-match"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": "true",
      "name": "semi-finals-match",
      "switch": ""
    },
    model: {
      value: _vm.semiFinalsHaveOtherFormat,
      callback: function callback($$v) {
        _vm.semiFinalsHaveOtherFormat = $$v;
      },
      expression: "semiFinalsHaveOtherFormat"
    }
  })], 1) : _vm._e(), _vm.semiFinalsHaveOtherFormat ? _c('b-form-group', {
    staticClass: "flex-fill"
  }, [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("Semi-Final format?")]), _c('validation-provider', {
    attrs: {
      "name": "format",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var componentErrors = _ref13.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.matchFormatOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "format"
          },
          model: {
            value: _vm.semiFinalsFormat,
            callback: function callback($$v) {
              _vm.semiFinalsFormat = $$v;
            },
            expression: "semiFinalsFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 3362706920)
  })], 1) : _vm._e(), _c('hr'), _c('b-form-checkbox', {
    staticClass: "mt-1 mb-1",
    model: {
      value: _vm.enableRegistration,
      callback: function callback($$v) {
        _vm.enableRegistration = $$v;
      },
      expression: "enableRegistration"
    }
  }, [_vm._v(" Enable registrations ")]), _c('label', [_vm._v(" If checked users have to register and join tournament on our platform. If you are running offline event or you want to enter players/teams and score manually then please uncheck this option. When you start tournament empty bracket will appear and you will have to enter teams/players. ")]), _vm.enableRegistration ? _c('div', [_c('label', [_vm._v("Registration open")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "locale": _vm.getUserLocale(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "start-weekday": 1,
      "name": "checkin-start-date",
      "placeholder": ""
    },
    model: {
      value: _vm.registrationOpenDate,
      callback: function callback($$v) {
        _vm.registrationOpenDate = $$v;
      },
      expression: "registrationOpenDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": ""
    },
    model: {
      value: _vm.registrationOpenTime,
      callback: function callback($$v) {
        _vm.registrationOpenTime = $$v;
      },
      expression: "registrationOpenTime"
    }
  })], 1)], 1), _c('label', [_vm._v("Registration close")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "locale": _vm.getUserLocale(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "start-weekday": 1,
      "name": "checkin-start-date",
      "placeholder": ""
    },
    model: {
      value: _vm.registrationCloseDate,
      callback: function callback($$v) {
        _vm.registrationCloseDate = $$v;
      },
      expression: "registrationCloseDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": ""
    },
    model: {
      value: _vm.registrationCloseTime,
      callback: function callback($$v) {
        _vm.registrationCloseTime = $$v;
      },
      expression: "registrationCloseTime"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-form-checkbox', {
    staticClass: "mt-1 mb-1",
    model: {
      value: _vm.enableCheckin,
      callback: function callback($$v) {
        _vm.enableCheckin = $$v;
      },
      expression: "enableCheckin"
    }
  }, [_vm._v(" Enable Check-in ")]), _c('label', {
    staticClass: "mb-2"
  }, [_vm._v(" If check-in is enabled teams/players need to check-in before tournament starts and only checked-in teams/players can participate. You can later setup when check-in starts and ends. This option can be very useful for free tournaments to avoid more than usual no-show matches in tournament. Keep in mind that teams/players that don’t check-in in time and tournament is started they can’t join and participate. ")]), _vm.enableCheckin ? _c('div', [_c('label', [_vm._v("Checkin start")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "locale": _vm.getUserLocale(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "start-weekday": 1,
      "name": "checkin-start-date",
      "placeholder": ""
    },
    model: {
      value: _vm.checkinStartDate,
      callback: function callback($$v) {
        _vm.checkinStartDate = $$v;
      },
      expression: "checkinStartDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": ""
    },
    model: {
      value: _vm.checkinStartTime,
      callback: function callback($$v) {
        _vm.checkinStartTime = $$v;
      },
      expression: "checkinStartTime"
    }
  })], 1)], 1), _c('label', [_vm._v("Checkin close")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "locale": _vm.getUserLocale(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "start-weekday": 1,
      "name": "checkin-close-date",
      "placeholder": ""
    },
    model: {
      value: _vm.checkinCloseDate,
      callback: function callback($$v) {
        _vm.checkinCloseDate = $$v;
      },
      expression: "checkinCloseDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": ""
    },
    model: {
      value: _vm.checkinCloseTime,
      callback: function callback($$v) {
        _vm.checkinCloseTime = $$v;
      },
      expression: "checkinCloseTime"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "mt-1",
    model: {
      value: _vm.checkinEndOnStart,
      callback: function callback($$v) {
        _vm.checkinEndOnStart = $$v;
      },
      expression: "checkinEndOnStart"
    }
  }, [_vm._v(" End Checkin when tournament is started ")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Below you can add all info for participants, mention sponsors etc.",
      "label-for": "participants-info"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "name": "participants-info"
    },
    model: {
      value: _vm.info,
      callback: function callback($$v) {
        _vm.info = $$v;
      },
      expression: "info"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Here please add only tournament rules",
      "label-for": "tournament-rules"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "name": "tournament-rules"
    },
    model: {
      value: _vm.rules,
      callback: function callback($$v) {
        _vm.rules = $$v;
      },
      expression: "rules"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Tournament type and prizes"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "mt-1",
    model: {
      value: _vm.hasEntryFee,
      callback: function callback($$v) {
        _vm.hasEntryFee = $$v;
      },
      expression: "hasEntryFee"
    }
  }, [_vm._v(" Enable participation fee ")])], 1), _vm.hasEntryFee ? _c('b-form-group', {
    attrs: {
      "label": "Entry fee for player/team",
      "label-for": "fee-amount"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "fee-amount",
      "placeholder": "Fee amount",
      "type": "number"
    },
    model: {
      value: _vm.entryFee,
      callback: function callback($$v) {
        _vm.entryFee = $$v;
      },
      expression: "entryFee"
    }
  })], 1) : _vm._e(), _vm.hasEntryFee ? _c('b-form-group', {
    attrs: {
      "label": "Your fee in % (earning)",
      "label-for": "fee-amount-percent"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "fee-amount-percent",
      "placeholder": "Fee amount",
      "type": "number"
    },
    model: {
      value: _vm.organizerFee,
      callback: function callback($$v) {
        _vm.organizerFee = $$v;
      },
      expression: "organizerFee"
    }
  })], 1) : _vm._e()], 1)], 1), _vm.entryFee ? _c('b-row') : _vm._e(), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Add your prizes",
      "label-for": "prizes"
    }
  }, [_c('label', {
    staticClass: "mt-1 mb-1"
  }, [_vm._v(" Add your prizes If you have prizes that will be delivered by you please enter them below (each prize in new line). Keep in mind that its crucial for you to deliver promised prizes after event. If you would like to give money prize to participants please get in touch with us. ")]), _c('b-form-textarea', {
    attrs: {
      "name": "prizes"
    },
    model: {
      value: _vm.prizes,
      callback: function callback($$v) {
        _vm.prizes = $$v;
      },
      expression: "prizes"
    }
  })], 1)], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "title": "Tournament staff"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Staff",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var _vm$organizer;
        var componentErrors = _ref14.errors;
        return [_vm._l((_vm$organizer = _vm.organizer) === null || _vm$organizer === void 0 ? void 0 : _vm$organizer.members, function (member) {
          var _member$user, _member$user2, _member$user3, _member$user4;
          return _c('b-form-checkbox', {
            key: (_member$user = member.user) === null || _member$user === void 0 ? void 0 : _member$user.id,
            staticClass: "mr-1",
            attrs: {
              "button-variant": _vm.staff && _vm.staff.indexOf((_member$user2 = member.user) === null || _member$user2 === void 0 ? void 0 : _member$user2.id) !== -1 ? 'primary' : 'outline-primary',
              "value": (_member$user3 = member.user) === null || _member$user3 === void 0 ? void 0 : _member$user3.id,
              "button": "",
              "size": "sm"
            },
            model: {
              value: _vm.staff,
              callback: function callback($$v) {
                _vm.staff = $$v;
              },
              expression: "staff"
            }
          }, [_vm._v(" " + _vm._s((_member$user4 = member.user) === null || _member$user4 === void 0 ? void 0 : _member$user4.name) + " ")]);
        }), _c('div', {
          staticClass: "mt-1"
        }, [_c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])])];
      }
    }])
  })], 1), _c('dismissible-message-box', {
    attrs: {
      "auto-dismiss": 5000,
      "show": _vm.showErrorNotifications,
      "type": "danger"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.errors[0]) + " ")], 1), _vm.showSuccessNotifications ? _c('dismissible-message-box', {
    attrs: {
      "auto-dismiss": 5000,
      "show": _vm.showSuccessNotifications,
      "type": "success"
    }
  }, [_vm._v(" Tournament successfully created ")]) : _vm._e(), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.createTournament($event);
      }
    }
  }, [_vm._v(" Create new tournament ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }